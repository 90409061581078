// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsible-gambling_M110m{padding:16px 8px 0}.responsible-gambling__content-block_wtLC2{display:flex;flex-direction:column;row-gap:14px;align-items:flex-start;margin:16px 8px}.responsible-gambling__content-block--button_HelQK{display:inline-flex;flex-direction:column;width:100%;max-width:268px}html.app__layout--desktop .responsible-gambling__download-button_RyH6a{margin-top:24px;text-align:left}.responsible-gambling--label-bottom-margin_eyE0N{margin-bottom:32px}.responsible-gambling__support-button_Yptxt{width:100%;-webkit-text-decoration:none;text-decoration:none}html.app__layout--desktop .responsible-gambling__support-button_Yptxt{width:calc(100% - 40px)}.responsible-gambling__hint-block_g4Vru{margin:0}.responsible-gambling__hint-block-time_yGT30{color:var(--ErrorText)}html.app__layout--desktop .responsible-gambling__hint-block_g4Vru{margin:0}.responsible-gambling__select-period_C7yEL{margin:16px 0 20px}html.app__layout--desktop .responsible-gambling_M110m{padding:16px 24px}.responsible-gambling__important_mA8cH{font-weight:500;color:var(--ErrorText)}.complex-restriction_dCjMt{padding:16px 24px}html.app__layout--desktop .complex-restriction_dCjMt{padding:16px 24px}.pp-8_vxda5{padding:0 8px}html.app__layout--desktop .pp-8_vxda5{padding:0 8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsible-gambling": `responsible-gambling_M110m`,
	"responsible-gambling__content-block": `responsible-gambling__content-block_wtLC2`,
	"responsible-gambling__content-block--button": `responsible-gambling__content-block--button_HelQK`,
	"responsible-gambling__download-button": `responsible-gambling__download-button_RyH6a`,
	"responsible-gambling--label-bottom-margin": `responsible-gambling--label-bottom-margin_eyE0N`,
	"responsible-gambling__support-button": `responsible-gambling__support-button_Yptxt`,
	"responsible-gambling__hint-block": `responsible-gambling__hint-block_g4Vru`,
	"responsible-gambling__hint-block-time": `responsible-gambling__hint-block-time_yGT30`,
	"responsible-gambling__select-period": `responsible-gambling__select-period_C7yEL`,
	"responsible-gambling__important": `responsible-gambling__important_mA8cH`,
	"complex-restriction": `complex-restriction_dCjMt`,
	"pp-8": `pp-8_vxda5`
};
export default ___CSS_LOADER_EXPORT___;
