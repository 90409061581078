// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsible-gambling_Rbre0{padding:16px 8px 0}.responsible-gambling__content-block_nntzl{display:flex;flex-direction:column;row-gap:14px;align-items:flex-start;margin:16px 8px}.responsible-gambling__content-block--button_n5DyM{display:inline-flex;flex-direction:column;width:100%;max-width:268px}html.app__layout--desktop .responsible-gambling__download-button_JprIt{margin-top:24px;text-align:left}.responsible-gambling--label-bottom-margin_f1sON{margin-bottom:32px}.responsible-gambling__support-button_PnfPO{width:100%;-webkit-text-decoration:none;text-decoration:none}html.app__layout--desktop .responsible-gambling__support-button_PnfPO{width:calc(100% - 40px)}.responsible-gambling__hint-block_RzJBe{margin:0}.responsible-gambling__hint-block-time_zIw5o{color:var(--ErrorText)}html.app__layout--desktop .responsible-gambling__hint-block_RzJBe{margin:0}.responsible-gambling__select-period_MFEDQ{margin:16px 0 20px}html.app__layout--desktop .responsible-gambling_Rbre0{padding:16px 24px}.partial-restriction_S9Kis{padding:16px 24px}html.app__layout--desktop .partial-restriction_S9Kis{padding:16px 24px}.pp-8_cUQ6K{padding:0 8px}html.app__layout--desktop .pp-8_cUQ6K{padding:0 8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsible-gambling": `responsible-gambling_Rbre0`,
	"responsible-gambling__content-block": `responsible-gambling__content-block_nntzl`,
	"responsible-gambling__content-block--button": `responsible-gambling__content-block--button_n5DyM`,
	"responsible-gambling__download-button": `responsible-gambling__download-button_JprIt`,
	"responsible-gambling--label-bottom-margin": `responsible-gambling--label-bottom-margin_f1sON`,
	"responsible-gambling__support-button": `responsible-gambling__support-button_PnfPO`,
	"responsible-gambling__hint-block": `responsible-gambling__hint-block_RzJBe`,
	"responsible-gambling__hint-block-time": `responsible-gambling__hint-block-time_zIw5o`,
	"responsible-gambling__select-period": `responsible-gambling__select-period_MFEDQ`,
	"partial-restriction": `partial-restriction_S9Kis`,
	"pp-8": `pp-8_cUQ6K`
};
export default ___CSS_LOADER_EXPORT___;
