// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsible-gambling_LxMOo{padding:16px 8px 0}.responsible-gambling__content-block_guSpZ{display:flex;flex-direction:column;row-gap:14px;align-items:flex-start;margin:16px 8px}.responsible-gambling__content-block--button_ycyUU{display:inline-flex;flex-direction:column;width:100%;max-width:268px}html.app__layout--desktop .responsible-gambling__download-button_BmCFc{margin-top:24px;text-align:left}.responsible-gambling--label-bottom-margin_fiFbS{margin-bottom:32px}.responsible-gambling__support-button_JPm3o{width:100%;-webkit-text-decoration:none;text-decoration:none}html.app__layout--desktop .responsible-gambling__support-button_JPm3o{width:calc(100% - 40px)}.responsible-gambling__hint-block_U7W2v{margin:0}.responsible-gambling__hint-block-time_CXRC3{color:var(--ErrorText)}html.app__layout--desktop .responsible-gambling__hint-block_U7W2v{margin:0}.responsible-gambling__select-period_BmV3L{margin:16px 0 20px}html.app__layout--desktop .responsible-gambling_LxMOo{padding:16px 24px}html.app__layout--desktop .self-exclusion_mG1SD{padding:16px 24px}.self-exclusion_mG1SD .responsible-gambling__content-block_guSpZ{margin:16px 0}.self-exclusion_mG1SD .responsible-gambling__content-block--button_ycyUU{max-width:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsible-gambling": `responsible-gambling_LxMOo`,
	"responsible-gambling__content-block": `responsible-gambling__content-block_guSpZ`,
	"responsible-gambling__content-block--button": `responsible-gambling__content-block--button_ycyUU`,
	"responsible-gambling__download-button": `responsible-gambling__download-button_BmCFc`,
	"responsible-gambling--label-bottom-margin": `responsible-gambling--label-bottom-margin_fiFbS`,
	"responsible-gambling__support-button": `responsible-gambling__support-button_JPm3o`,
	"responsible-gambling__hint-block": `responsible-gambling__hint-block_U7W2v`,
	"responsible-gambling__hint-block-time": `responsible-gambling__hint-block-time_CXRC3`,
	"responsible-gambling__select-period": `responsible-gambling__select-period_BmV3L`,
	"self-exclusion": `self-exclusion_mG1SD`
};
export default ___CSS_LOADER_EXPORT___;
