// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsible-gambling_rRgvB{padding:16px 8px 0}.responsible-gambling__content-block_vB5Wm{display:flex;flex-direction:column;row-gap:14px;align-items:flex-start;margin:16px 8px}.responsible-gambling__content-block--button_QD1e6{display:inline-flex;flex-direction:column;width:100%;max-width:268px}html.app__layout--desktop .responsible-gambling__download-button_hS1IV{margin-top:24px;text-align:left}.responsible-gambling--label-bottom-margin_o1zbU{margin-bottom:32px}.responsible-gambling__support-button_iVItD{width:100%;-webkit-text-decoration:none;text-decoration:none}html.app__layout--desktop .responsible-gambling__support-button_iVItD{width:calc(100% - 40px)}.responsible-gambling__hint-block_Ri0aH{margin:0}.responsible-gambling__hint-block-time_flUEz{color:var(--ErrorText)}html.app__layout--desktop .responsible-gambling__hint-block_Ri0aH{margin:0}.responsible-gambling__select-period_j_9Ni{margin:16px 0 20px}html.app__layout--desktop .responsible-gambling_rRgvB{padding:16px 24px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsible-gambling": `responsible-gambling_rRgvB`,
	"responsible-gambling__content-block": `responsible-gambling__content-block_vB5Wm`,
	"responsible-gambling__content-block--button": `responsible-gambling__content-block--button_QD1e6`,
	"responsible-gambling__download-button": `responsible-gambling__download-button_hS1IV`,
	"responsible-gambling--label-bottom-margin": `responsible-gambling--label-bottom-margin_o1zbU`,
	"responsible-gambling__support-button": `responsible-gambling__support-button_iVItD`,
	"responsible-gambling__hint-block": `responsible-gambling__hint-block_Ri0aH`,
	"responsible-gambling__hint-block-time": `responsible-gambling__hint-block-time_flUEz`,
	"responsible-gambling__select-period": `responsible-gambling__select-period_j_9Ni`
};
export default ___CSS_LOADER_EXPORT___;
